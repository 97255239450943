import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retryWhen, concatMap, delay, tap } from 'rxjs/operators';
import { SnackBarLoaderComponent } from '../../shared/app-common/snack-bar-loader/snack-bar-loader.component';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarService } from '../../services/snackbar/snack-bar.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpRequestResponceInterceptor implements HttpInterceptor {

  retryLoader: MatSnackBarRef<SnackBarLoaderComponent>;
  
  constructor(private notify: SnackBarService, private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen(errors => errors.pipe(
        concatMap((error) => {
          console.error("error", errors);
          if (error.status === 0) { // && count < 10
            if (this.retryLoader) {
              if (!this.retryLoader.instance.loading) {
                this.retryLoader = this.notify.loader('Connection Lost');
              }
            } else this.retryLoader = this.notify.loader('Connection Lost');
            return of(error.status);
          }
          else if (error.status === 500) { this.notify.error('Server Error, Please try again later', 'Close') }
          else if (error.status === 401) {
            // this.router.navigate(['/account/login']);
            this.notify.error('Authentication Failed.', 'Close');
          }
          // else if (error.status === 400) { this.notify.error(error.error, 'Close') }
          console.log(error);
          return throwError(error);
        }),
        delay(5000)
      )),
      tap((res) => {
        console.log("res........",res);
        if (res instanceof HttpResponse && this.retryLoader) {
          this.retryLoader.dismiss();
          this.retryLoader = null;
          this.notify.info('Back Online', 'Ok');
        }
      }),
    )
  }
}
